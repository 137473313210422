<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        action-create
        disable-loading-on-refresh
        @refresh="getItems"
        @create="hRedirect('payment-methods-add')"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage"/>

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refaccountCampaignListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter.input"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')"
        >

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button v-on:click="deleteItem(data.item.id)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger"
                      class="mr-2" type="submit" size="sm"
            >
              {{ $t('Delete') }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>
      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows"
      />
    </b-card-actions>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { BCardActions } from '@core/components/b-card-actions'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    TableHeader,
    TableFooter,
    BCardActions,

    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        {
          key: 'name',
          sortable: true,
          label: this.$i18n.t('Title'),
        },
        {
          key: 'account_owner',
          sortable: true,
          label: this.$i18n.t('Account owner'),
        },
        {
          key: 'payment_type',
          sortable: true,
          label: this.$i18n.t('Payment Method'),
          formatter() {
            return 'IBAN'
          }
        },
        {
          key: 'last_four',
          sortable: true,
          label: this.$i18n.t('last 4 digits of the Card/IBAN'),
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions')
        }
      ],
      items: [],
    }
  },

  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/client/paymentmethods`)
    },

    deleteItem(id) {
      this.helperDeleteItem(
          `/client/paymentmethods/${id}`,
      )
    },

    formatName(value, key, item) {
      if (item.firstname == null && item.name == null) return item.email

      if (!item.firstname) return item.name

      return item.firstname + ' ' + item.name

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
